import React, { FC } from "react";
import { Badge, BadgeProps } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const BadgeStyle = makeStyles((theme) => ({
  badge: {
    border: `2px solid ${theme.palette.common.white}`,
    backgroundColor: "#e6007e",
    color: "white"
  },
  badgeAnchorTopRight: {
    right: -5,
    top: 3
  },
  badgeAnchorTopLeft: {
    top: 3
  }
}));

interface StyledBadgeProps extends BadgeProps {
  className?: string;
}

const StyledBadge = ({
  className,
  anchorOrigin,
  badgeContent,
  color,
  children,
  ...rest
}: StyledBadgeProps): ReturnType<FC> => {
  const styles = BadgeStyle();

  return (
    <Badge
      {...rest}
      anchorOrigin={anchorOrigin}
      badgeContent={badgeContent}
      color={color}
      max={99}
      classes={{
        badge: clsx(styles.badge, className),
        anchorOriginTopRightRectangle: styles.badgeAnchorTopRight,
        anchorOriginTopLeftRectangle: styles.badgeAnchorTopLeft
      }}
    >
      {children}
    </Badge>
  );
};

export default StyledBadge;
