import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { MuiThemeProvider, CssBaseline } from "@material-ui/core";
import theme from "./theme";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import ReactGA from "react-ga";
import { store } from "./redux/store";
import { AppProvider } from "./context/app-context";
import { Provider as RollbarProvider, ErrorBoundary } from "@rollbar/react"; // Provider imports 'rollbar'
import RollbarTracking from "utils/RollbarTracker";

if (process.env.environment === "production") {
  ReactGA.initialize("UA-185235650");
}

// disable console logs on production
if (process.env.REACT_APP_ENVIRONMENT === "production" || process.env.REACT_APP_ENVIRONMENT === "prod") {
  console.log = () => {};
}

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <RollbarProvider instance={RollbarTracking.rollbar}>
            <ErrorBoundary>
              <AppProvider>
                <App />
              </AppProvider>
            </ErrorBoundary>
          </RollbarProvider>
        </Provider>
      </MuiThemeProvider>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
