import React, { FC, cloneElement } from "react";
import { useLocation } from "react-router-dom";
import cx from "classnames";
import {
  AppBar,
  Badge,
  Box,
  LinearProgress,
  IconButton,
  Toolbar,
  Typography,
  useScrollTrigger
} from "@material-ui/core";
import { FavoriteBorderOutlined, DescriptionOutlined } from "@material-ui/icons";
import Share from "../appHeader/share-desktop-v2.svg";
import { HeaderStyle } from "./DesktopAppHeaderStyles";
import ShopOutlinedIcon from "../../buttons/ShopOutlinedIcon";
import ShopIcon from "../../buttons/ShopIconV2";
import { useAppSelector } from "../../../hooks";
import { HOW_IT_WORKS_PATH, STOREHOME_PATH, HOME_PATH, PROFILE_PERSONAL_PATH } from "../../../constants/paths";
import useNav from "../../../hooks/useNav";
import { HEADER, COLORS } from "../../../style";
import { useApp } from "../../../context/app-context";
import UserIcon from "../../customIcons/UserIcon";
import GridWrapper from "../appLayout/GridWrapper";
import DesktopSyncHeader from "../syncHeader/desktop/DesktopSyncHeader";

interface DesktopAppHeaderV2Props {
  variant: "header1" | "header2";
}
const ElevationScroll = (props: any) => {
  const { children, window, headerVariant } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined
  });
  return cloneElement(children, {
    style: {
      boxShadow: trigger ? "0px 8px 8px -8px rgba(0,0,0,0.1)" : "none",
      backgroundColor: HEADER[headerVariant] ?? "#02060B"
    }
  });
};

const DesktopAppHeaderV2: FC<DesktopAppHeaderV2Props> = ({ variant = "header1" }: DesktopAppHeaderV2Props) => {
  const classes = HeaderStyle();
  const { setPromptLogin, encryptedUserId, loggedInUserFullname, setOpenShareDialog } = useApp();
  const location = useLocation();
  const { goTo } = useNav();
  const { email, nickname, gender, wishlistCount } = useAppSelector((state) => state.user.data);
  const isProfileComplete = encryptedUserId
    ? email && email.length !== 0 && nickname && nickname.length !== 0 && gender && gender.length !== 0
    : false;
  const hideShare = !encryptedUserId;
  const hideHowTo = true;

  const navigateToHome = () => {
    if (encryptedUserId) {
      goTo(STOREHOME_PATH);
    } else {
      goTo(HOME_PATH);
    }
  };

  const isLoggedIn = encryptedUserId ? true : false;

  const shouldShowSyncHeader = () => {
    return !location.pathname.includes("/profile");
  }

  const onOpenShare = () => {
    if (encryptedUserId) {
      if (isProfileComplete) {
        setOpenShareDialog(true);
      } else {
        goTo(PROFILE_PERSONAL_PATH);
      }
    } else {
      setPromptLogin({ promptLogin: true, returnTo: undefined, registration: false });
    }
  };

  return (
    <>
      <ElevationScroll headerVariant={variant}>
        <AppBar position="fixed" elevation={0} className={classes.appBarStyle2}>
          <GridWrapper style={{ width: "100%" }}>
            <Toolbar className={classes.toolbarStyle2} style={{ width: "100%" }}>
              <Box>
                <IconButton
                  classes={{ label: classes.logoButtonLabel }}
                  className={classes.logoButton}
                  disableRipple
                  onClick={navigateToHome}
                >
                  <img
                    src={"/assets/header_logo_v4.svg"}
                    alt="GiftMe Logo"
                    className={classes.logoStyle}
                    style={{ width: 94 }}
                  />
                </IconButton>
                <Box className={cx(classes.buttonContainer)}>
                  <IconButton
                    aria-label="Store page"
                    className={location.pathname === STOREHOME_PATH ? classes.selectedIconV3 : classes.iconButtonV3}
                    onClick={() => goTo(STOREHOME_PATH)}
                    disableRipple
                  >
                    <ShopIcon width={18.659} height={23.25} />
                    <Typography style={{ paddingLeft: 5, lineHeight: "16.8px" }}>Shop</Typography>
                    <div
                      className={cx({
                        [classes.selectedButtonContainerV3]: location.pathname.includes("/store")
                      })}
                    />
                  </IconButton>
                </Box>
                {encryptedUserId && (
                  <Box className={cx(classes.buttonContainer)}>
                    <IconButton
                      aria-label="Wishlist page"
                      className={
                        location.pathname.includes("/wishlist") ? classes.selectedIconV3 : classes.iconButtonV3
                      }
                      onClick={() => goTo(`/gift/wishlist/${encryptedUserId}`)}
                      disableRipple
                    >
                      <Badge
                        badgeContent={wishlistCount}
                        max={99}
                        classes={{
                          anchorOriginTopRightRectangle: location.pathname.includes("/wishlist")
                            ? classes.selectedWishlistIcon
                            : classes.wishlistIcon
                        }}
                      >
                        <FavoriteBorderOutlined />
                        <Typography style={{ paddingLeft: 5 }}>Wishlist</Typography>
                      </Badge>
                      <div
                        className={cx({
                          [classes.selectedButtonContainerV3]: location.pathname.includes("/wishlist")
                        })}
                      />
                    </IconButton>
                  </Box>
                )}
                {!encryptedUserId && (
                  <Box className={cx(classes.buttonContainer)}>
                    <IconButton
                      className={
                        location.pathname === HOW_IT_WORKS_PATH ? classes.selectedIconV3 : classes.iconButtonV3
                      }
                      onClick={() => goTo(HOW_IT_WORKS_PATH)}
                      disableRipple
                    >
                      <img src="/assets/home/how-to.svg" />
                      <Typography
                        style={{
                          paddingLeft: 5,
                          whiteSpace: "nowrap",
                          lineHeight: "16.8px"
                        }}
                      >
                        How To
                      </Typography>
                      <div
                        className={cx({
                          [classes.selectedButtonContainerV3]: location.pathname === HOW_IT_WORKS_PATH
                        })}
                      />
                    </IconButton>
                  </Box>
                )}
                {!hideHowTo && (
                  <Box className={cx(classes.buttonContainer)}>
                    <IconButton
                      disableRipple
                      className={location.pathname === HOME_PATH ? classes.selectedIcon : classes.iconButton}
                      onClick={() => goTo(HOME_PATH)}
                    >
                      <DescriptionOutlined />
                      <Typography style={{ paddingLeft: 5, color: COLORS.WHITE }}>How To</Typography>
                      <div
                        className={cx({
                          [classes.selectedButtonContainerV3]: location.pathname === "/"
                        })}
                      />
                    </IconButton>
                  </Box>
                )}
              </Box>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                {!hideShare && (
                  <Box className={classes.buttonContainer} style={{ height: "unset" }}>
                    <IconButton
                      aria-label="Share"
                      className={classes.headerIconStyle}
                      onClick={onOpenShare}
                      disableRipple
                    >
                      <img src={Share} alt="secure" height="24" width="24" />
                      <Typography
                        style={{
                          paddingLeft: 5,
                          whiteSpace: "nowrap",
                          lineHeight: "16.8px",
                          color: COLORS.WHITE
                        }}
                      >
                        Share
                      </Typography>
                    </IconButton>
                  </Box>
                )}
                <Box
                  className={cx(classes.buttonContainer)}
                  style={{
                    padding: 0
                  }}
                >
                  <IconButton
                    disableRipple
                    className={
                      location.pathname.startsWith("/profile")
                        ? classes.selectedIconAccountBtn
                        : classes.iconButtonAccountBtn
                    }
                    onClick={() =>
                      encryptedUserId
                        ? goTo("/profile/account")
                        : setPromptLogin({
                            promptLogin: true,
                            returnTo: STOREHOME_PATH,
                            registration: false
                          })
                    }
                    cy-data="header-signin-btn"
                  >
                    <UserIcon stroke={COLORS.WHITE} />

                    <Typography style={{ paddingLeft: 5, lineHeight: "16.8px" }}>
                      {!encryptedUserId ? (
                        "Sign Up / Login"
                      ) : encryptedUserId &&
                        (typeof loggedInUserFullname === "undefined" || loggedInUserFullname.length <= 0) ? (
                        "My Account"
                      ) : nickname ? (
                        `Hi, ${nickname}`
                      ) : (
                        <div style={{ position: "relative", width: 30 }}>
                          <LinearProgress style={{ width: "100%", position: "absolute", height: 2 }} />
                        </div>
                      )}
                    </Typography>
                    <div
                      className={cx({
                        [classes.selectedButtonContainerV3]: location.pathname.startsWith("/profile")
                      })}
                    />
                  </IconButton>
                </Box>
              </Box>
            </Toolbar>
          </GridWrapper>
          {shouldShowSyncHeader() ? <DesktopSyncHeader /> : null}
        </AppBar>
      </ElevationScroll>
      <Box className={isLoggedIn ? classes.appBarSpaceStyle : classes.appBarSpaceStyleLoggedOut} />
    </>
  );
};

export default DesktopAppHeaderV2;
